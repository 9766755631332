<template>
  <div>
    <router-view></router-view>
    <van-tabbar v-model="active" route>
      <van-tabbar-item icon="wap-home" to="/WapMyHome">试验管理</van-tabbar-item>
      <van-tabbar-item icon="graphic" to="/WapMes" v-if="userType=='Employee'">生产管理</van-tabbar-item>
      <van-tabbar-item icon="manager" to="/WapMy">账号信息</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
  data() {
    return {
      active: 0,
      userType: "",
    };
  },
  mounted() {
    let that = this;
    that.axios.post("Sys_UserInfo/GetUserType").then(function (response) {
      that.userType = response.data.data;
    });
  },
};
</script>

<style>
</style>